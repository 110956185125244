import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import styled from 'styled-components';
import Footer from '../sections/Footer';

const pageQuery = graphql`
  {
    datoCmsEnquiryPage {
      title
      headingText
      bannerImage {
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

const PageContent = styled.div`
  padding-top: 78px;
  min-height: calc(100vh - 302px);

  input:not([type='checkbox']) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const inputClass =
  'appearance-none block w-full bg-orange-light text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-orange-theme';

export default function ContactPage({ contactInfo }) {
  const data = useStaticQuery(pageQuery);
  const {
    title,
    headingText,
    bannerImage,
    seoMetaTags,
  } = data.datoCmsEnquiryPage;

  return (
    <>
      <SEO meta={seoMetaTags} />
      <PageContent className="">
        <div
          className="bg-contain bg-no-repeat bg-center"
          style={{
            backgroundImage: `url(${bannerImage?.url})`,
            height: '50vh',
          }}
        ></div>
        {/* <div className="mt-1 h-1 w-16 bg-orange-theme mx-auto"></div> */}

        <h1 className="text-4xl text-center font-bold my-8 tracking-wider text-gray-900">
          {headingText}
        </h1>
        <div className="container pb-10">
          <form
            className="w-full mx-auto"
            name="Enquiry Form"
            method="POST"
            data-netlify="true"
            action="/thankyou"
          >
            <input type="hidden" name="form-name" value="Enquiry Form" />
            <div className="flex flex-wrap mb-6 justify-center">
              <div className="w-full md:w-3/4">
                <div className="flex flex-wrap mb-6">
                  <div className="w-full px-3">
                    <label
                      htmlFor="name"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Your Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      className={inputClass}
                      type="text"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      htmlFor="phone"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Phone Number
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      className={inputClass}
                      type="tel"
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      htmlFor="email"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      name="email"
                      className={inputClass}
                      type="email"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      htmlFor="property-name"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Project / Property Name
                    </label>
                    <input
                      id="property-name"
                      name="property name"
                      className={inputClass}
                      type="text"
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      htmlFor="location"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Location
                    </label>
                    <input
                      id="location"
                      name="location"
                      className={inputClass}
                      type="text"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      htmlFor="property-built-up"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Property Built Up (Sq Feet)
                    </label>
                    <input
                      id="property-built-up"
                      name="built up"
                      className={inputClass}
                      type="text"
                    />
                    {/* <p className="text-gray-600 text-xs italic">
                      In Square Feet
                    </p> */}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      htmlFor="property-type"
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    >
                      Property Type
                    </label>
                    <div className="relative">
                      <select
                        name="property type"
                        className={inputClass}
                        id="property-type"
                      >
                        <option value="Residential" name="Residential">
                          Residential
                        </option>
                        <option value="Commercial" name="Commercial">
                          Commercial
                        </option>
                        <option value="Kitchens" name="Kitchens">
                          Kitchens
                        </option>
                        <option value="Offices" name="Offices">
                          Offices
                        </option>
                        <option
                          value="Hotels & Apartments"
                          name="Hotels & Apartments"
                        >
                          Hotels & Apartments
                        </option>
                        <option value="Co-Living" name="Co-Living">
                          Co-Living
                        </option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="block mx-auto shadow bg-orange-theme hover:bg-gray-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Submit Enquiry
            </button>
          </form>
        </div>
      </PageContent>
      <Footer contactInfo={contactInfo} />
    </>
  );
}
